const links = [
  {
    label: 'github',
    url: 'https://github.com/coderamos',
  },
  // {
  //   label: 'instagram',
  //   url: 'https://www.instagram.com/diegocoderamos/',
  // },
  {
    label: 'linkedin',
    url: 'https://www.linkedin.com/in/coderamos/',
  },
  // {
  //   label: 'twitter',
  //   url: 'https://twitter.com/diegocoderamos',
  // },
]

export default links
